.root {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bottom{
  justify-content: center;
  text-align: center;
  margin-left: 1.2rem;
  margin-right: 1.2rem;
  margin-bottom: 1.2rem;
}

.title {
  margin-top: 14rem;
  margin-bottom: 32px;
  font-size: 6rem;
  text-justify: center;
  text-align: center;
  
}

@media (max-width: 490px) {
  .title {
    margin-top: 0.5rem;
    margin-bottom: 32px;
    font-size: 5rem;
    text-justify: center;
    text-align: center;
  }
}

.welcome {
  margin-bottom: 1.2rem;
  justify-content: center;
  text-align: center;
  
}

@media (max-width: 490px) {
.welcome {
  margin-bottom: 1.8rem;
  justify-content: center;
  text-align: center;
  font-size: 0.8rem;
  margin-left: 2rem;
  margin-right: 2rem;
}
}

.msgBold {
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 1.3rem;
}

.demoTest{
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 1.3rem;
  color: #ffb347;
}
.msg{
  margin-bottom: 8px;
  font-size: 1.3rem;
}

.link {
  display: flex;
  align-items: center;
  text-decoration: none;
  background-color: #1BD760;
  color: white;
  height: 60px;
  border-radius: 20px;
  padding: 0px 24px;
  margin-bottom: 12rem;
  font-size: 1.2rem;
  
}

@media (max-width: 490px) {
  .link {
    display: flex;
    align-items: center;
    text-decoration: none;
    background-color: #1BD760;
    color: white;
    height: 50px;
    border-radius: 20px;
    padding: 0px 24px;
    margin-bottom: 8rem;
    
  }
}
